import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import styles from './HomePage.module.scss'

import isEmpty from '../../utils/isEmpty';
import Hero from '../../components/Hero';
import HeroCard from '../../components/HeroCard';
import StreamField from '../../components/StreamField';

const HomePage = ({ hero, heroCard, preamble, contentBlocks }) => {
    return (
        <div className={styles["HomePage"]}>
            <Hero {...hero} />

            <div id="main-content" />
            <div
                dangerouslySetInnerHTML={{ __html: preamble }}
                className={styles["HomePage__Preamble"]}
            />

            {!isEmpty(heroCard) && <HeroCard {...heroCard} />}

            <StreamField items={contentBlocks} />
        </div>
    );
};

HomePage.defaultProps = {
    hero: {},
    heroCard: {},
    contentBlocks: [],
    preamble: '',
};

HomePage.propTypes = {
    hero: PropTypes.object,
    heroCard: PropTypes.object,
    preamble: PropTypes.string,
    contentBlocks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default basePageWrap(HomePage);
