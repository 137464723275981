import React from 'react';
import PropTypes from 'prop-types';
import RawHtml from '../RawHtml';

import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

import styles from './HeroCard.module.scss'

const HeroCard = ({ whereToGo, whatToDo, planYourTrip }) => {
    return (
        <div className={styles["HeroCard"]}>
            <div className={styles["HeroCard__Container"]}>
                <div className={styles["HeroCard__Wrapper"]}>
                    <Card {...whereToGo} />
                    <Card {...whatToDo} />
                    <Explore {...planYourTrip} />
                </div>
            </div>
        </div>
    );
};

HeroCard.propTypes = {
    whereToGo: PropTypes.object,
    whatToDo: PropTypes.object,
    planYourTrip: PropTypes.object,
};
HeroCard.defaultProps = {
    whereToGo: {},
    whatToDo: {},
    planYourTrip: {},
};

const Explore = ({ href, content }) => {
    const { t } = useTranslation("common")
    return (
        <div className={styles["HeroCard__Card"]}>
            <h2 className={styles["HeroCard__Title"] + " " + styles["HeroCard__Title--Icon"] + " " + styles["HeroCard__Title--Icon-Explore"]}>
                <span>{t('herocard.plantrip')}</span>
            </h2>
            <div className={styles["HeroCard__Content"]}>
                <RawHtml html={content} modifier="Inner" />
            </div>
            <div className={styles["HeroCard__ButtonWrapper"]}>
                <a className={styles["HeroCard__Button"]} href={href}>
                    {t('herocard.startplanning')}
                </a>
            </div>
        </div>
    );
};

Explore.propTypes = {
    href: PropTypes.string,
    content: PropTypes.string,
};
Explore.defaultProps = {
    href: '',
    content: '',
};

const Card = ({ subLinks, mainLink, icon }) => {
    const { t } = useTranslation("common")
    const cardTitleClasses = classNames(styles["HeroCard__Title"], {
        [`HeroCard__Title--Icon HeroCard__Title--Icon-${icon}`]: icon !== '',
    });

    return (
        <div className={styles["HeroCard__Card"]}>
            <h2 className={cardTitleClasses}>{mainLink.title}</h2>
            <ul className={styles["HeroCard__Content"]}>
                {subLinks.map((item, idx) => {
                    // Since list blocks can't be numerically limited
                    // in wagtail yet, make sure we render at the most
                    // 3 here.
                    if (idx > 2) {
                        return null;
                    }

                    return (
                        <li key={idx}>
                            <a className={styles["HeroCard__Link"]} href={item.href}>
                                <span
                                    className={styles["HeroCard__Icon"] + " " + styles["HeroCard__Icon--Arrow"]}
                                    aria-hidden={true}
                                />
                                {item.title}
                            </a>
                        </li>
                    );
                })}
            </ul>
            <div className={styles["HeroCard__ButtonWrapper"]}>
                <a
                    className={styles["HeroCard__Button"]}
                    aria-label={t('herocard.showAllDescriptive', {
                        title: mainLink.title,
                    })}
                    href={mainLink.href}>
                    {t('herocard.showall')}
                </a>
            </div>
        </div>
    );
};

Card.propTypes = {
    subLinks: PropTypes.array,
    mainLink: PropTypes.object,
    icon: PropTypes.string,
};

Card.defaultProps = {
    subLinks: [],
    mainLink: {},
    icon: '',
};

export default HeroCard;
