import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import styles from './RawHtml.module.scss'

const RawHtml = ({ html, isExpandable, modifier, untranslatedModifiers = [], facts }) => {
    const { t } = useTranslation("common")
    const [isOpen, setIsOpen] = useState(false);

    const expand = () => {
        setIsOpen(true);
    };

    const classes = classNames(styles["RawHtml"], {
        [styles["RawHtml--Expandable"]]: isExpandable & !isOpen,
        [styles[`RawHtml--${modifier}`]]: typeof modifier !== 'undefined',
    }, untranslatedModifiers.map(x => styles[x]));

    return (
        <div className={classes}>
            <AnimateHeight
                height={!isExpandable || isOpen ? 'auto' : 200}
                duration={450}>
                <div
                    className={styles["RawHtml__Html"]}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </AnimateHeight>
            {isExpandable && !isOpen && (
                <button onClick={expand} className={styles["RawHtml__Expand"]}>
                    {t('rawHtml.expand')}
                </button>
            )}
            {facts && (
                <div
                    className={styles["RawHtml__Facts"]}
                    dangerouslySetInnerHTML={{ __html: facts }}
                />
            )}
        </div>
    );
};

RawHtml.propTypes = {
    html: PropTypes.string,
    isExpandable: PropTypes.bool,
    modifier: PropTypes.string,
    facts: PropTypes.string,
    untranslatedModifiers: PropTypes.arrayOf(PropTypes.string),
};

RawHtml.defaultProps = {
    html: '',
    facts: '',
    isExpandable: false,
};

export default RawHtml;
